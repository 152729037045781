// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

div.login, div.signup {
  max-width: 400px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
  background-color: #2b2a33;
  animation-duration: 400ms;
  animation-name: translate;
  animation-timing-function: ease-in-out;
}
form.login, form.signup {
  max-width: 400px;
  margin: 0 auto;
}

.login input[type="text"],
.login input[type="password"],
.signup input[type="text"],
.signup input[type="password"] {
  max-width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  color:#000;
}

form.login h1, form.signup h1 {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginSignup.css"],"names":[],"mappings":";;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,sCAAsC;AACxC;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;;;;EAIE,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\n\ndiv.login, div.signup {\n  max-width: 400px;\n  margin: 0 auto;\n  align-items: center;\n  text-align: center;\n  padding-bottom: 30px;\n  background-color: #2b2a33;\n  animation-duration: 400ms;\n  animation-name: translate;\n  animation-timing-function: ease-in-out;\n}\nform.login, form.signup {\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.login input[type=\"text\"],\n.login input[type=\"password\"],\n.signup input[type=\"text\"],\n.signup input[type=\"password\"] {\n  max-width: 80%;\n  display: block;\n  margin-right: auto;\n  margin-left: auto;\n  color:#000;\n}\n\nform.login h1, form.signup h1 {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
