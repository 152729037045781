// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center;
}

.header a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 512px) {
  .header {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":["\n.header {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n  margin-bottom: 50px;\n  align-items: center;\n}\n\n.header a {\n  text-decoration: none;\n}\n\n@media screen and (max-width: 768px) {\n  .header {\n    margin-bottom: 25px;\n  }\n}\n@media screen and (max-width: 512px) {\n  .header {\n    margin-top: 0px;\n    margin-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
